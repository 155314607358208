import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Paper,
} from "@material-ui/core";
import { GiftsData } from "./index";
import API from "../../../../_shared/axios";
import {
  AlertContextType,
  OrderCommonPromoCode,
  OrderCommonPromoCodeItem,
  ProductRow,
  PromotionCategory,
} from "../../../../_shared/types";
import Typography from "@material-ui/core/Typography";
import ProductItem from "./ProductItem";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FlexRow from "../FlexRow";
import { alertError, getErrorMsg } from "../../../../_shared/utils";
import { AlertContext } from "../../../_shared/ToastList";

export type StateFullCats = PromotionCategory & {
  chosenProducts: Array<{
    productId: number;
    variantId?: number;
    count: number;
  }>;
  total: number;
  max: number;
};

const useStyles = makeStyles({
  root: {
    padding: "0!important",
    display: "flex",
    alignItems: "flexStart",
    height: "80vh",
    minWidth: "520px",
    overflow: "hidden",
  },
  productsBox: {
    height: "100%",
    overflowY: "scroll",
    width: "calc(100% - 250px)",
    scrollbarWidth: "none",
  },
  catsBox: {
    width: "250px",
    height: "100%",
    boxShadow: "-6px 1px 8px -6px rgba(34, 60, 80, 0.47)",
    scrollbarWidth: "none",
  },
  categoryPaper: {
    backgroundColor: "#f4f4f4",
    width: "240px",
    margin: "15px 0 0 10px",
    padding: "10px",
  },
  productItem: {
    width: "150px",
    height: "150px",
  },
  selectedCat: {
    border: "solid 1px #F50057",
    borderRadius: "10px",
  },
  thumbnail: {
    width: "95px",
    height: "95px",
  },
  itemControl: {
    marginTop: "10px",
    padding: "5px",
  },
  catItem: {
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "4px",
    margin: "15px 0 15px 0",
  },
  catImg: {
    margin: "5px",
    height: "60px",
    width: "60px",
  },
  chosenProdInfo: {
    margin: "10px 0 5px 0",
  },
  productName: {
    margin: "5px",
    fontWeight: "bold",
    fontSize: "16px",
  },
  variantName: {
    margin: "0 5px 0 5px",
    fontSize: "14px",
    color: "#555555",
    display: "block",
  },
  quantity: {
    fontWeight: "bold",
    marginBottom: 0,
    display: "inline-flex",
    alignItems: "center",
    width: "100px",
    color: "#3f51b5",
  },
  quantitySpan: {
    marginLeft: "4px",
    marginRight: "4px",
  },
  quantityControl: {
    backgroundColor: "#efefef",
    border: "0px solid white",
    padding: "4px",
    borderRadius: "16px",
    display: "inline-flex",
    alignItems: "center",
    flexDirection: "row",
    justifyItems: "space-between",
    color: "black",
  },
});

const GiftsModal: FC<{
  apply: (data: OrderCommonPromoCodeItem[]) => void;
  data: GiftsData;
  commonProducts: Array<ProductRow>;
  close: () => void;
}> = ({ data, commonProducts, apply, close }) => {
  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);

  const [selectedCategory, setSelectedCategory] = useState<number>(-1);
  const [catsInfo, setCatsInfo] = useState<{
    [key: string]: StateFullCats;
  }>({});
  const [selectedProduct, setSelectedProduct] = useState<number | null>(null);
  const [prodsExtraInfo, setExtraInfo] = useState<Array<ProductRow>>([]);

  const productIds = useMemo(() => {
    if (Object.values(catsInfo).length && selectedCategory !== -1) {
      return catsInfo[selectedCategory].products.map((p) => p.id);
    } else {
      return [];
    }
  }, [selectedCategory, catsInfo]);

  const generateVariantName = (variantId: number, prod: ProductRow) => {
    const variant = prod.variants.find((v) => v.variantId === variantId);
    if (variant) {
      return variant.values
        .map((val) => `${val.optionData}: ${val.optionValueData}`)
        .join(" ");
    } else {
      return "";
    }
  };

  const sendCode = () => {
    const presents: OrderCommonPromoCodeItem[] = [];

    Object.values(catsInfo).forEach((v) => {
      v.chosenProducts.forEach((pr) => {
        const prr = prodsExtraInfo.find((cp) => cp.id === pr.productId);

        if (!prr) return;

        if (pr.variantId) {
          presents.push({
            count: pr.count,
            name: prr.name,
            productId: pr.productId,
            promotionCategoryId: v.id,
            variant: {
              variantId: pr.variantId,
              values: prr.variants.find(
                (variant) => variant.variantId === pr.variantId
              )!.values,
            },
          });
        } else {
          presents.push({
            count: pr.count,
            name: prr.name,
            productId: pr.productId,
            promotionCategoryId: v.id,
            variant: null,
          });
        }
      });
    });
    apply(presents);
  };

  const addItem = (
    productId: number,
    variantId?: number,
    categoryIdx?: number
  ) => {
    const categories = { ...catsInfo };

    if (categories[categoryIdx ? categoryIdx : selectedCategory].max === 1) {
      categories[
        categoryIdx ? categoryIdx : selectedCategory
      ].chosenProducts = [
        {
          productId: productId,
          variantId: variantId,
          count: 1,
        },
      ];
      categories[categoryIdx ? categoryIdx : selectedCategory].total = 1;
      return setCatsInfo(categories);
    }

    const total = categories[
      categoryIdx ? categoryIdx : selectedCategory
    ].chosenProducts.reduce((summ, product) => product.count + summ, 0);

    if (total != categories[categoryIdx ? categoryIdx : selectedCategory].max) {
      const idx = categories[
        categoryIdx ? categoryIdx : selectedCategory
      ].chosenProducts.findIndex((pr) =>
        pr.variantId
          ? pr.productId === productId && pr.variantId === variantId
          : pr.productId === productId
      );

      if (idx !== -1) {
        categories[categoryIdx ? categoryIdx : selectedCategory].chosenProducts[
          idx
        ].count += 1;
      } else {
        categories[
          categoryIdx ? categoryIdx : selectedCategory
        ].chosenProducts.push({
          productId: productId,
          variantId: variantId,
          count: 1,
        });
      }
      categories[categoryIdx ? categoryIdx : selectedCategory].total = total;
      setCatsInfo(categories);
    }
  };

  const removeItem = (
    productId: number,
    variantId?: number,
    categoryIdx?: number
  ) => {
    const categories = { ...catsInfo };

    const idx = categories[
      categoryIdx ? categoryIdx : selectedCategory
    ].chosenProducts.findIndex((pr) =>
      pr.variantId
        ? pr.productId === productId && pr.variantId === variantId
        : pr.productId === productId
    );

    if (idx !== -1) {
      if (
        categories[categoryIdx ? categoryIdx : selectedCategory].chosenProducts[
          idx
        ].count > 1
      ) {
        categories[categoryIdx ? categoryIdx : selectedCategory].chosenProducts[
          idx
        ].count -= 1;
      } else {
        categories[
          categoryIdx ? categoryIdx : selectedCategory
        ].chosenProducts.splice(idx, 1);
      }
    }
    const total = categories[
      categoryIdx ? categoryIdx : selectedCategory
    ].chosenProducts.reduce((summ, product) => product.count + summ, 0);

    categories[categoryIdx ? categoryIdx : selectedCategory].total = total;

    setCatsInfo(categories);
  };

  useEffect(() => {
    if (Object.values(catsInfo).length) {
      const ids: Array<number> = [];
      Object.values(catsInfo).forEach((c) => {
        c.products.forEach((p) => {
          ids.push(p.id);
        });
      });
      const pData: Array<ProductRow> = [];
      Promise.all(ids.map((i) => API.get(`products/${i}`)))
        .then((responses) => {
          responses.forEach((r) => pData.push(r.data));
          setExtraInfo(pData);
        })
        .catch((e) =>
          alertError(
            alertContext,
            getErrorMsg(e.response, "Ошибка получения данных о продукте")
          )
        );
    }
  }, [catsInfo]);

  useEffect(() => {
    Promise.all(
      Object.values(data).map(async (entry) => {
        return API.get(`promotionCategories/${entry.promoCatId}`);
      })
    )
      .then((responses) => {
        const catsData: { [key: string]: StateFullCats } = {};
        responses.forEach(
          (c, i) =>
            (catsData[String(i)] = {
              ...c.data,
              chosenProducts: [],
              max: data[String(c.data.id)].count,
              total: 0,
            })
        );
        setCatsInfo(catsData);
      })
      .catch((e) =>
        alertError(
          alertContext,
          getErrorMsg(e.response, "Ошибка получения данных о категориях")
        )
      );
  }, [data]);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      onBackdropClick={() => close()}
      maxWidth={"md"}
    >
      <DialogContent>
        <Box className={classes.root}>
          <Box className={classes.productsBox}>
            <Grid
              container
              justify={"space-around"}
              direction={"row"}
              spacing={2}
            >
              {prodsExtraInfo
                .filter((pr) => {
                  if (data[selectedCategory]?.productId) {
                    return data[selectedCategory]?.productId === pr.id;
                  } else {
                    return productIds.includes(pr.id);
                  }
                })
                .map((p) => (
                  <ProductItem
                    key={p.id}
                    setSelectedProduct={(id) => setSelectedProduct(id)}
                    product={p}
                    addItem={(id, variantId) => addItem(id, variantId)}
                    addCount={(id, variantId) => console.log(id, variantId)}
                    removeCount={(id, variantId) => console.log(id, variantId)}
                  />
                ))}
            </Grid>
          </Box>
          <Box className={classes.catsBox}>
            <Grid
              container
              direction={"column"}
              justify={"space-between"}
              style={{ height: "100%" }}
            >
              <Grid
                container
                direction={"column"}
                spacing={2}
                alignItems={"center"}
              >
                {Object.entries(catsInfo).map(([id, val]) => (
                  <Paper
                    key={data[val.id].promoCatId}
                    elevation={0}
                    className={
                      Number(id) === selectedCategory
                        ? [classes.categoryPaper, classes.selectedCat].join(" ")
                        : classes.categoryPaper
                    }
                    onClick={() => setSelectedCategory(Number(id))}
                  >
                    <Typography variant={"h6"}>{`${data[val.id].name} [${
                      val.total
                    }/${val.max}]`}</Typography>
                    <Box>
                      {val.chosenProducts.map((product) => {
                        const prodInfo = prodsExtraInfo.find(
                          (pr) => pr.id === product.productId
                        );

                        if (prodInfo) {
                          return (
                            <Paper className={classes.catItem}>
                              <Grid
                                container
                                direction={"column"}
                                wrap={"wrap"}
                              >
                                <text className={classes.productName}>
                                  {prodInfo.name}
                                </text>
                                <text className={classes.variantName}>
                                  {product.variantId
                                    ? generateVariantName(
                                        product.variantId,
                                        prodInfo
                                      )
                                    : ""}
                                </text>
                              </Grid>
                              <Box className={classes.chosenProdInfo}>
                                <FlexRow className={classes.quantity}>
                                  <text className={classes.variantName}>
                                    Количество:
                                  </text>
                                  <Box className={classes.quantityControl}>
                                    <RemoveCircleOutlineIcon
                                      style={{ marginRight: "3.5px" }}
                                      onClick={() =>
                                        removeItem(
                                          product.productId,
                                          product.variantId,
                                          Number(id)
                                        )
                                      }
                                    />
                                    {product.count}
                                    <AddCircleOutlineIcon
                                      style={{ marginLeft: "3.5px" }}
                                      onClick={() =>
                                        addItem(
                                          product.productId,
                                          product.variantId,
                                          Number(id)
                                        )
                                      }
                                    />
                                  </Box>
                                </FlexRow>
                              </Box>
                            </Paper>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                    </Box>
                  </Paper>
                ))}
              </Grid>
              <Grid container direction={"row"} justify={"space-around"}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={sendCode}
                >
                  Сохранить
                </Button>
                <Button variant={"outlined"} color={"secondary"}>
                  Отменить
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GiftsModal;
