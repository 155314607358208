import React, { FC, useState, useEffect, useContext } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import {AlertContextType, PromocodeHistoryEntry, PromoCodeValuesItem} from "../../_shared/types";
import { Search } from "@material-ui/icons";
import API from "../../_shared/axios";
import { getPromoValuesQuery } from "../OrdersPage/shared";
import { alertError } from "../../_shared/utils";
import { AlertContext } from "../_shared/ToastList";
import moment from "moment";

export const PromocodeValues: FC<{
  id: number;
  type: number;
}> = ({ id,type}) => {
  const [searchString, setSearchString] = useState("");
  const [entries, setEntries] = useState<PromoCodeValuesItem[]>([]);
  const [filtered, setFiltered] = useState<PromoCodeValuesItem[]>([]);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const alertContext = useContext<AlertContextType>(AlertContext);
  const codeList=filtered.sort((a, b) => a.promoCodeValueId < b.promoCodeValueId ? 1 : -1);

  const updateList = () =>
    API.get(getPromoValuesQuery(id, searchString, page + 1, pageSize));

  useEffect(() => {
    updateList()
      .then(({ data }) => {
        setEntries(data.items);
        setTotalItems(data.totalItems);
      })
      .catch((error) => {
        alertError(alertContext, "Ошибка получения значений промокода");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, searchString]);

  useEffect(() => {
    if (searchString.length > 3) setPage(0);
  }, [searchString]);

  const isNumeric = (value: string) => /^\d+$/.test(value) || value === "";
  useEffect(() => {
    setFiltered(
        entries
            .filter(
                (v) =>
                    String(v.value)
                        .toLowerCase()
                        .includes(searchString.toLowerCase())
                    ||
                    String(v.customerPhoneNumber)
                        .toLowerCase()
                        .includes(searchString.toLowerCase())
            )

    );
  }, [entries, searchString]);
  return (
    <Box>
      <Box>
        <Grid
          direction={"row"}
          justify={"space-between"}
          alignItems={"center"}
          container
        >
          <TextField
            style={{ width: "400px" }}
            margin={"dense"}
            type={"text"}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            placeholder={"Номер телефона, значение промокода..."}
            variant={"outlined"}
            size={"small"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Box>
      <TableContainer>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>Значение промокода</TableCell>
              <TableCell>Номер клиента</TableCell>
              <TableCell>Дата окончания</TableCell>
              {type === 2 &&(<TableCell>Дата начала</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {codeList.map((value) => (
              <TableRow key={value.promoCodeValueId}>
                <TableCell>{value.value}</TableCell>
                <TableCell>{value.customerPhoneNumber}</TableCell>
                <TableCell>
                  {(value.expirationDate &&
                    moment(value.expirationDate).format("DD.MM.YYYY")) ||
                    "-"}
                </TableCell>
                {type === 2 && ( <TableCell>
                  {((value.startDate &&
                      moment(value.startDate).add(1, 'day').format("DD.MM.YYYY"))) ||
                  "-"}
                </TableCell>)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={"Записей на странице"}
        labelDisplayedRows={({ from, to }) => `${from}-${to} из ${totalItems}`}
        rowsPerPageOptions={[10, 15, 25, 50]}
        component="div"
        count={totalItems}
        rowsPerPage={pageSize}
        onChangeRowsPerPage={(e) => setPageSize(Number(e.target.value))}
        page={page}
        onChangePage={(e, newPage) => setPage(newPage ? newPage : 0)}
      />
    </Box>
  );
};
