import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  AlertContextType,
  StreetSearchResponse,
} from "../../../../_shared/types";
import API from "../../../../_shared/axios";
import {
  alertError,
  getErrorMsg,
  getIntFromString,
} from "../../../../_shared/utils";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { KladrObjectType } from "../../../StreetsPage/_shared/types";
import { AlertContext } from "../../../_shared/ToastList";
import { Shop } from "../../../_shared/types";
import styles from "./index.module.scss";
import Dialog from "@material-ui/core/Dialog";

const CreateAddressForm = ({
  onClose,
  customerId,
  onAddressCreate,
  orderId,
}: {
  onClose: () => void;
  customerId: number;
  orderId?: number;
  onAddressCreate: (customerId: number, addressId: number) => void;
}) => {
  const alertContext = useContext<AlertContextType>(AlertContext);

  const [kladrObjectTypes, setKladrObjectTypes] = useState<KladrObjectType[]>(
    []
  );
  const [searchList, setSearchList] = useState<StreetSearchResponse[]>([]);
  const [house, setHouse] = useState("");
  const [apartment, setApartment] = useState<number | null>(null);
  const [entrance, setEntrance] = useState<number | null>(null);
  const [code, setCode] = useState<number | null>(null);
  const [floor, setFloor] = useState<number | null>(null);
  const [commentary, setCommentary] = useState("");
  const [shopId, setShopId] = useState(0);
  const [kladrId, setKladrId] = useState(0);
  const [shops, setShops] = useState<Shop[]>([]);
  const [kladrObjectType, setKladrObjectType] = useState<any>("");
  const [streetName, setStreetName] = useState("");
  const [checkSave, setCheckSave] = useState(false);

  useEffect(() => {
    API.get(`/kladrObjects/types`)
      .then(({ data }: { data: KladrObjectType[] }) => {
        setKladrObjectTypes(data.filter((el) => el.level === 10));
      })
      .catch((error) => {
        alertError(
          alertContext,
          "Ошибка получения списка типов кладр-объектов"
        );
      });
    API.get(`/shops`)
      .then(({ data }) => {
        setShops(data);
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения списка пиццерий")
        );
      });
  }, []);

  const requiredFieldsFilled = () =>
    (kladrId !== 0 || streetName) &&
    house !== "" &&
    apartment !== 0 &&
    shopId !== 0;

  const fetch = (event: any, newInputValue: string) => {
    setStreetName(newInputValue);
    if (newInputValue.length > 2) {
      setSearchList([]);
      let streetsList: StreetSearchResponse[] = [];
      const url = encodeURI(
        `kladrObjectsByLevel?level=10&name=${newInputValue}`
      );
      API.get(url)
        .then(({ data }) => {
          streetsList = [...streetsList, ...data];
          API.get(`/customers/${customerId}/addresses`)
            .then(({ data }) => {
              setSearchList([...data, ...streetsList]);
            })
            .catch((error) =>
              alertError(alertContext, "Ошибка получения адресов клиента")
            );
        })
        .catch((error) => alertError(alertContext, "Ошибка поиска улицы"));
    } else {
      setSearchList([]);
    }
  };

  return (
    <Dialog open={true} fullWidth maxWidth={"sm"} onBackdropClick={onClose}>
      <div className={styles.wrapper}>
        <div>
          <Autocomplete
            id="combo-box-demo"
            fullWidth
            onBlur={() => setSearchList([])}
            freeSolo
            inputValue={streetName}
            onInputChange={fetch}
            options={searchList}
            getOptionLabel={(option: StreetSearchResponse) =>
              option.fullName ? option.fullName : option.name!
            }
            renderInput={(params) => <TextField {...params} label="Улица *" />}
            onChange={(
              event,
              newValue: StreetSearchResponse | null | undefined | string
            ) => {
              if (newValue && typeof newValue !== "string") {
                if (newValue.fullName) {
                  const {
                    house,
                    apartment,
                    entrance,
                    code,
                    floor,
                    commentary,
                    kladrId,
                    klardObjectTypeId,
                  } = newValue;
                  const shop = newValue.shopId;
                  setKladrId(kladrId!);
                  setHouse(house!);
                  setStreetName(
                    newValue.fullName ? newValue.fullName : newValue.name!
                  );
                  setApartment(+apartment!);
                  setEntrance(+entrance!);
                  setCode(+code!);
                  setFloor(+floor!);
                  setCommentary(commentary!);
                  setKladrObjectType(
                    kladrObjectTypes.find(
                      (el) => el.id === +klardObjectTypeId!
                    )!.nameRu
                  );
                  setShopId(shop!);
                } else {
                  setShopId(0);
                  const { id, kladrObjectTypeId } = newValue;
                  setKladrId(id!);
                  setKladrObjectType(
                    kladrObjectTypes.find((el) => el.id === kladrObjectTypeId)!
                      .nameRu
                  );
                  setHouse("");
                  setApartment(0);
                  setEntrance(0);
                  setCode(0);
                  setFloor(0);
                  setCommentary("");
                }
              }
            }}
          />
        </div>
        <br />
        <FormControl fullWidth>
          <InputLabel>Тип улицы</InputLabel>
          <Select value={kladrObjectType}>
            {kladrObjectTypes.map((c, idx) => (
              <MenuItem
                onClick={() => setKladrObjectType(c.id)}
                key={idx}
                value={c.id}
              >
                {c.nameRu}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <div>
          <TextField
            label="Дом *"
            type="text"
            inputProps={{ maxLength: 10 }}
            value={house}
            onChange={(e) => {
              let newValue = e.target.value;
              if (newValue.slice(0, 1) === "0") newValue = newValue.slice(1);
              setHouse(newValue);
            }}
          />
          <TextField
            label="Квартира *"
            inputProps={{ maxLength: 10 }}
            type="text"
            value={apartment === 0 ? null : apartment}
            onChange={(e) =>
              e.target.value === " " || e.target.value === ""
                ? setApartment(null)
                : setApartment(getIntFromString(e.target.value))
            }
          />
          <TextField
            label="Подъезд"
            inputProps={{ maxLength: 10 }}
            type="text"
            value={entrance === 0 ? null : entrance}
            onChange={(e) =>
              e.target.value === " " || e.target.value === ""
                ? setEntrance(null)
                : setEntrance(getIntFromString(e.target.value))
            }
          />
        </div>
        <br />
        <div>
          <TextField
            fullWidth
            label="Код"
            inputProps={{ maxLength: 10 }}
            type="text"
            value={code === 0 ? null : code}
            onChange={(e) =>
              e.target.value === " " || e.target.value === ""
                ? setCode(null)
                : setCode(getIntFromString(e.target.value))
            }
          />
          <TextField
            fullWidth
            label="Этаж"
            inputProps={{ maxLength: 10 }}
            type="text"
            value={floor === 0 ? null : floor}
            onChange={(e) =>
              e.target.value === " " || e.target.value === ""
                ? setFloor(null)
                : setFloor(getIntFromString(e.target.value))
            }
          />
        </div>
        <br />
        <TextField
          value={commentary || ""}
          onChange={(e) => setCommentary(e.target.value)}
          label="Комментарий"
          multiline
        />
        <br />
        <br />
        <Select value={shopId} onChange={(e: any) => setShopId(e.target.value)}>
          <MenuItem value={0} disabled>
            Пиццерия *
          </MenuItem>
          {shops.map((c, idx) => (
            <MenuItem key={idx} value={c.id}>
              {c.name}
            </MenuItem>
          ))}
        </Select>
        <br />
        <DialogActions className={styles.actions}>
          {!checkSave && (
            <Button type={"submit"} color={"secondary"} onClick={onClose}>
              Отмена
            </Button>
          )}
          {checkSave && (
            <Button
              type={"submit"}
              color={"secondary"}
              onClick={() => setCheckSave(false)}
            >
              Назад
            </Button>
          )}
          {!checkSave && (
            <Button
              disabled={!requiredFieldsFilled()}
              onClick={() => setCheckSave(true)}
            >
              Создать
            </Button>
          )}
          {checkSave && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                const addressData = {
                  orderId: orderId ? orderId : null,
                  customerId: customerId,
                  kladrId: kladrId ? kladrId : undefined,
                  kladrName: kladrId ? undefined : streetName,
                  house: house,
                  entrance: entrance ? entrance : undefined,
                  floor: floor ? floor : undefined,
                  apartment,
                  shopId: shopId !== 0 ? shopId : undefined,
                  aroundTheClockShopId: shopId !== 0 ? shopId : undefined,
                  code: code ? code : undefined,
                  addressComment: commentary,
                  isAddressError: false,
                  kladrObjectCoordinateId: undefined,
                  kladrObjectTypeId: kladrId ? undefined : kladrObjectType,
                };
                API.post(`/addresses`, addressData)
                  .then((resp) => onAddressCreate(customerId, resp.data))
                  .catch((error) => {
                    alertError(alertContext, "Ошибка создания адреса");
                  });
              }}
              color="primary"
              type="submit"
              disabled={!requiredFieldsFilled()}
            >
              Подтвердить
            </Button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default CreateAddressForm;
