import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import moment, { Moment } from "moment/moment";

import {
  Box,
  ButtonGroup,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import API from "../../_shared/axios";
import {
  AlertContextType,
  Client,
  CodeProductSet,
  FormPromoCode,
  newPromoCodeList,
  ProductRow,
  Promocode,
  PromotionCategory,
  PromoCodeValues,
} from "../../_shared/types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import {
  alertError,
  alertSuccess,
  buildQueryParamsJSON,
  copyObject,
  getClientName,
} from "../../_shared/utils";
import { AlertContext } from "../_shared/ToastList";
import Item from "./Items";
import ActionGroup from "./__shared/ActionGroup";
import { isNumber, throttle } from "lodash";
import { ProgressBar } from "../_shared/ProgressBar";
import ModalCloseConfirm from "./__shared/ModalCloseConfirm";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { useDebounce } from "@react-hook/debounce";
import PromocodeHistory from "./PromocodeHistory";
import { KeyboardBackspace } from "@material-ui/icons";
import { PromocodeValues } from "./PromocodeValues";

const useStyles = makeStyles({
  alertTitle:{
    alignItems: 'center',
    justifyContent: 'center',
  },
  alertTitleText:{
    paddingTop:'5px'
  },
  main: {
    padding: "15px",
  },
  root: { margin: "10px" },
  checkCasual: {
    width: "210px",
    padding: "5px 0 5px 0",
    cursor: "pointer",
  },
  checkWithInput: {
    width: "440px",
    padding: "5px 0 5px 0",
  },
  checkWithInput2: {
    width: "704px",
    padding: "5px 0 5px 0",
  },
  checkWithInput3: {
    width: "1050px",
    padding: "5px 0 5px 0",
  },
  block: {
    padding: "20px 0 20px 0",
  },
  error: {
    color: "#f44336",
  },
  codeWordForm: {
    display: "flex",
    gap: "15px",
  },
  pointer: {
    cursor: "pointer",
  },
  clientSearch: {
    marginTop: "1px",
    marginBottom: "6px",
    width: "170px",
  },
});

const PromocodesForm: FC<{
  id?: number | "new";
  onClose: () => void;
  onEditOrCreate: () => void;
}> = ({ id, onClose, onEditOrCreate }) => {
  const alertContext = useContext<AlertContextType>(AlertContext);
  const classes = useStyles();

  // state промокода
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [forApp, setForApp] = useState(false);
  const [forSite, setForSite] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [pickUp, setPickUp] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [minPrice, setMinPrice] = useState(0);
  const [birthDay, setBirthday] = useState(false);
  const [firstOrder, setFirstOrder] = useState(false);
  const [individual, setIndividual] = useState(false);
  const [lifetime, setLifeTime] = useState(0);
  const [presents, setPresents] = useState<Array<CodeProductSet>>([]);
  const [productSets, setProductSets] = useState<Array<CodeProductSet>>([]);
  const [type, setType] = useState(0);
  const [showHistory, setShowHistory] = useState(false);
  const [showValues, setShowValues] = useState(false);
  const [someFieldChanged, setSomeFieldChanged] = useState(false);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [alerted, setAlerted] = useState(false);
  const [progress, setProgress] = useState(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [promoCodeForm, setPromoCodeForm] = useState<FormPromoCode>({
    customerId: 0,
    isRus: false,
    isEng: false,
    isNum: true,
    valuesCount: "0",
    startDate: "0",
  });
  const [promoCodeValues, setPromoCodeValues] = useState<
    PromoCodeValues | undefined
  >(undefined);
  const [newPromoCodesList, setNewPromoCodesList] = useState<newPromoCodeList>(
    []
  );
  const [clientSearchString, setClientSearchString] = useDebounce<string>(
    "",
    1000
  );
  const [clientsPage, setClientsPage] = useState<number>(1);
  const onChangeValue = (stateHook: any) => {
    setSomeFieldChanged(true);
    stateHook();
  };

  const isBlurredInit = {
    name: false,
    code: false,
    lifetime: false,
    condition: false,
  };
  const [isBlurred, setIsBlurred] = useState(isBlurredInit);
  const setBlurredField = (field: string) => {
    setIsBlurred({ ...isBlurred, [field]: true });
  };

  const [giftType, setGiftType] = useState<"discount" | "prods">("discount");
  const [promoCategories, setPromoCategories] = useState<
    Array<PromotionCategory>
  >([]);
  const [products, setProducts] = useState<Array<ProductRow>>([]);
  const [condition, setCondition] = useState<"price" | "goods">("price");

  const save = () => {
    const sendData = {
      name: name,
      forSite: forSite,
      type: type,
      forFirstOrder: firstOrder,
      forApplications: forApp,
      discount: discount ? discount : 0,
      forPickup: pickUp,
      forDelivery: delivery,
      minPrice: minPrice,
      forBirthday: birthDay,
      value: code,
      lifetime: lifetime === 0 ? null : lifetime,
      productSetList:
        condition === "goods"
          ? productSets.map((p: any) => {
              return {
                promotionCategoryId: p.promotionCategoryId,
                productId: p.productId,
                count: p.count,
                variantList: p.variantsList.map((v: any) => v.variantId),
              };
            })
          : [],
      presents:
        giftType === "prods"
          ? presents.map((p: any) => {
              return {
                promotionCategoryId: p.promotionCategoryId,
                productId: p.productId,
                count: p.count,
                variantList: p.variantsList.map((v: any) => v.variantId),
              };
            })
          : [],
    };
    if (isEdit(id)) {
      if (minPrice >= discount) {
        setProgress(true);
        API.patch(`/promoCodes/${id}`, sendData)
            .then(() => {
              alertSuccess(alertContext, "Сохранено");
              setProgress(false);
              onEditOrCreate();
              clearFieldAndClose();
            })
            .catch((resp) => {
              setProgress(false);
              if (resp.code === 401) {
                window.location.href = `${window.location.hostname}/auth`;
              } else {
                alertError(alertContext, "Ошибка загрузки данных");
              }

            });
      }
      else {
        alertError(
            alertContext,
            "Минимальная сумма заказа не может быть меньше размера скидки"
        );
      }
    } else if (isNew(id)) {
      if (minPrice >= discount) {
        setProgress(true);
        API.post(`/promoCodes/`, sendData)
          .then(() => {
            setProgress(false);
            alertSuccess(alertContext, "Сохранено");
            onEditOrCreate();
            clearFieldAndClose();
          })
          .catch((resp) => {
            setProgress(false);
            if (resp.code === 401) {
              window.location.href = `${window.location.hostname}/auth`;
            } else {
              alertError(alertContext, "Ошибка загрузки данных");
            }
          });
      } else {
        alertError(
          alertContext,
          "Минимальная сумма заказа не может быть меньше размера скидки"
        );
      }
    }
  };
  const isEdit = (id: any) => {
    return isNumber(id);
  };
  const isNew = (id: any) => {
    return id === "new";
  };

  const onSetChange = useCallback(
    (set: CodeProductSet, index: number) => {
      const newData = copyObject(productSets);
      newData[index] = set;
      onChangeValue(() => setProductSets(newData));
    },
    [productSets]
  );

  const onPresentChange = useCallback(
    (set: CodeProductSet, index: number) => {
      const newData = copyObject(presents);
      newData[index] = set;
      onChangeValue(() => setPresents(newData));
    },
    [presents]
  );

  const deleteSet = (setIndex: number) => {
    const arr = [...productSets];
    arr.splice(setIndex, 1);
    onChangeValue(() => setProductSets(arr));
  };
  const clearFieldAndClose = () => {
    setName("");
    setCode("");
    setAlerted(false);
    setForApp(false);
    setForSite(false);
    setDiscount(0);
    setPickUp(false);
    setDelivery(false);
    setMinPrice(0);
    setBirthday(false);
    setFirstOrder(false);
    setIndividual(false);
    setLifeTime(0);
    setProductSets([]);
    setPresents([]);
    setSomeFieldChanged(false);
    setPromoCodeForm({
      customerId: 0,
      isRus: false,
      isEng: false,
      isNum: false,
      valuesCount: "1",
      startDate: "0",
    });
    setNewPromoCodesList([]);
    setClientSearchString("");
    setClientsPage(1);

    onClose();
  };
  const deletePresents = (setIndex: number) => {
    const arr = [...presents];
    arr.splice(setIndex, 1);
    onChangeValue(() => setPresents(arr));
  };
  const generatePromoCodeHandler = () => {
    let generateCode = "1";
    if (type === 1) {
      generateCode = `promoCodes/individual/${id}?customerId=${promoCodeForm.customerId}&isRus=${promoCodeForm.isRus}&isEng=${promoCodeForm.isEng}&isNum=${promoCodeForm.isNum}`;
    }
    if (type === 2) {
      let promoStartDate = moment(promoCodeForm.startDate).format("MM.DD.YYYY")
      generateCode = `promoCodes/personal/${id}?startDate=${promoStartDate}&valuesCount=${promoCodeForm.valuesCount}&isRus=${promoCodeForm.isRus}&isEng=${promoCodeForm.isEng}&isNum=${promoCodeForm.isNum}`;
    }
    API.post(generateCode)
      .then((resp) => {
        setNewPromoCodesList((prev) => [
          ...prev,
          {
            client:
              clients.find((client) => client.id === promoCodeForm.customerId)
                ?.phoneNumber || "",
            code: resp.data,
          },
        ]);
        setCode(resp.data);
      })
      .catch((resp) => {
        if (resp.code === 401) {
          window.location.href = `${window.location.hostname}/auth`;
        } else {
          alertError(alertContext, "Ошибка загрузки данных");
        }
      });
  };
  const isValid =
    name !== "" &&
    (!productSets.length ||
      productSets.every((i) => i.promotionCategoryId !== null)) &&
    ((discount !== 0 && giftType === "discount") ||
      (presents.length > 0 &&
        giftType === "prods" &&
        presents.every((i) => i.promotionCategoryId !== null)));

  useEffect(() => {
    const paramString = clientSearchString
      ? `pageNumber=${clientsPage}&FilterParams[0].ColumnName=phoneNumber&FilterParams[0].FilterOption=3&FilterParams[0].filterValue=${clientSearchString}`
      : `pageNumber=${clientsPage}`;
    API.get(`/customers?${paramString}`)
      .then(({ data }: { data: { items: Client[] } }) => {
        if (clientsPage === 1) {
          setClients([...data.items]);
        } else {
          setClients((state) => [...data.items, ...state]);
        }
      })
      .catch((error) => {
        console.log(error);
        alertError(alertContext, "Ошибка получения списка клиентов");
      });
  }, [clientsPage, clientSearchString]);

  useEffect(() => {
    if (!isEdit(id)) {
      setCondition("price");
      setGiftType("discount");
      setPromoCodeValues(undefined);
      setIsBlurred(isBlurredInit);
    }
    if (isEdit(id)) {
      // setLoading((state) => {
      //   return { ...state, code: false };
      // });
      API.get(`promoCodes/${id}`)
        .then((resp) => {
          const codeData: Promocode = resp.data;
          setType(resp.data.type);
          setName(codeData.name);
          setCode(codeData.value);
          setForApp(codeData.forApplications);
          setForSite(codeData.forSite);
          setDiscount(codeData.discount);
          setPickUp(codeData.forPickup);
          setDelivery(codeData.forDelivery);
          setMinPrice(codeData.minPrice);
          setBirthday(codeData.forBirthday);
          setFirstOrder(codeData.forFirstOrder);
          setIndividual(codeData.individual);
          setLifeTime(codeData.lifetime);
          setPresents(codeData.presents);
          setProductSets(
            codeData.productSets.map((p) => {
              return {
                ...p,
                //@ts-ignore
                variantsList: p.productSetVariantsList,
              };
            })
          );
          setGiftType(
            codeData.discount !== 0 && codeData.discount !== null
              ? "discount"
              : "prods"
          );
          setCondition(
            codeData.minPrice !== 0 && codeData.minPrice !== null
              ? "price"
              : "goods"
          );

          // setLoading((state) => {
          //   return { ...state, code: false };
          // });

          API.get(`promoCode/${id}/values`)
            .then((resp) => {
              setPromoCodeValues(resp.data);
            })
            .catch((resp) => {
              if (resp.code === 401) {
                window.location.href = `${window.location.hostname}/auth`;
              } else {
                alertError(alertContext, "Ошибка загрузки данных");
              }
            });
        })
        .catch((resp) => {
          if (resp.code === 401) {
            window.location.href = `${window.location.hostname}/auth`;
          } else {
            alertError(alertContext, "Ошибка загрузки данных");
          }
        });
    }
    // setDataIsLoaded(true);
    // setLoading((state) => {
    //   return { ...state, code: false };
    // });
  }, [id]);

  useEffect(() => {
    API.get("promotionCategories")
      .then((resp) => {
        const data: any = resp.data;

        const catIds = data.map((d: any) => d.id);

        Promise.all(
          catIds.map(async (c: number) => {
            const resp = await API.get(`promotionCategories/${c}`);
            return resp.data;
          })
        ).then((c) => {
          // @ts-ignore
          setPromoCategories(c);
          // setLoading((state) => {
          //   return { ...state, cats: false };
          // });
        });
      })
      .catch((resp) => {
        if (resp.code === 401) {
          window.location.href = `${window.location.hostname}/auth`;
        } else {
          alertError(alertContext, "Ошибка загрузки данных");
        }
      });
  }, []);

  useEffect(() => {
    setProgress(true);
    API.get(`products`)
      .then((resp) => {
        const ids: Array<number> = resp.data.map((v: any) => v.id);
        // const prods: Array<ProductRow> = [];
        Promise.all(
          ids.map(async (i) => {
            const resp = await API.get(`products/${i}`);
            return resp.data;
          })
        ).then((p) => {
          setProducts(p);
          setProgress(false);
          // setLoading((state) => {
          //   return { ...state, prods: false };
          // });
        });
      })
      .catch((resp) => {
        if (resp.code === 401) {
          window.location.href = `${window.location.hostname}/auth`;
        } else {
          alertError(alertContext, "Ошибка загрузки данных");
        }
      });
  }, []);

  if (showHistory) {
    return (
      <Dialog
        onBackdropClick={() => setShowHistory(false)}
        fullWidth
        maxWidth={"lg"}
        open={!!id}
      >
        {id === "new" ? (
          <DialogTitle id="form-dialog-title">Новый промокод</DialogTitle>
        ) : (
          <DialogTitle id="form-dialog-title">
            {showHistory
              ? "История использования"
              : showValues
              ? "Значения промокода"
              : "Редактирование промокода"}


          </DialogTitle>
        )}
        <DialogContent>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<KeyboardBackspace />}
            style={{ marginBottom: "10px" }}
            onClick={() => {

              setShowHistory(false);
              setShowValues(false);
              setPromoCodeForm((prev) => ({
              ...prev,
              valuesCount: '0',
                startDate: '0',
            }));
            }}
          >
            Назад
          </Button>
          {isNumber(id) && <PromocodeHistory id={id} />}
        </DialogContent>
      </Dialog>
    );
  } else if (showValues) {
    return (
      <Dialog
        onBackdropClick={() => setShowValues(false)}
        fullWidth
        maxWidth={"lg"}
        open={!!id}
      >
        {id === "new" ? (
          <DialogTitle id="form-dialog-title">Новый промокод</DialogTitle>
        ) : (
          <DialogTitle id="form-dialog-title">
            {!showHistory && !showValues &&("Редактирование промокода")}
            {showValues && ("Список всех значений")}
            {showHistory && ("История использования")}
          </DialogTitle>
        )}
        <DialogContent>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<KeyboardBackspace />}
            style={{ marginBottom: "10px" }}
            onClick={() => {
              setShowHistory(false);
              setShowValues(false);
              setPromoCodeForm((prev) => ({
                ...prev,
                valuesCount: '0',
                startDate: '0',
              }));
            }}
          >
            Назад
          </Button>
          {isNumber(id) && <PromocodeValues id={id} type={type} />}
        </DialogContent>
      </Dialog>
    );
  } else {
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore

    return (
      <Dialog
        onBackdropClick={() =>
          someFieldChanged ? setAlerted(true) : clearFieldAndClose()
        }
        fullWidth
        maxWidth={"md"}
        open={!!id}
      >
        {id === "new" ? (
          <DialogTitle id="form-dialog-title">Новый промокод</DialogTitle>
        ) : (
          <DialogTitle id="form-dialog-title">
            Редактирование промокода
          </DialogTitle>
        )}
        <DialogContent>
          <Container className={classes.root}>
            <Paper className={classes.main}>
              <Box className={classes.block}>
                <TextField
                  label={"Название"}
                  value={name || ""}
                  required
                  onChange={(e) => onChangeValue(() => setName(e.target.value))}
                  error={!name && isBlurred.name}
                  onBlur={() => setBlurredField("name")}
                />
              </Box>
              <Box className={classes.block}>
                {id !== "new" && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowHistory(true)}
                  >
                    История использования
                  </Button>
                )}
                <br />
                <br />
                {id !== "new" && type !== 0 ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={promoCodeValues === undefined}
                    onClick={() => setShowValues(true)}
                  >
                    Список всех значений
                  </Button>
                ) : (
                  promoCodeValues?.items[0]?.value && (
                    <>
                      {`Значение: `}
                      <Box fontWeight="fontWeightBold">
                        {promoCodeValues?.items[0].value}
                      </Box>
                    </>
                  )
                )}
              </Box>
              <Divider />
              <Box className={classes.block}>
                <Grid
                  container
                  direction={"row"}
                  justify={"space-between"}
                  className={classes.checkCasual}
                  alignItems={"center"}
                >
                  {id === "new" && (
                    <RadioGroup
                      aria-label="promoСodeType"
                      defaultValue={type}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="0"
                        onChange={() => setType(0)}
                        control={<Radio />}
                        label="Общий"
                      />
                      <FormControlLabel
                        value="1"
                        onChange={() => setType(1)}
                        control={<Radio />}
                        label="Индивидуальный"
                      />
                      <FormControlLabel
                        value="2"
                        onChange={() => setType(2)}
                        control={<Radio />}
                        label="Персональный"
                      />
                    </RadioGroup>
                  )}
                  <div>

                    {type === 0 && id !== "new" && (
                      <Typography  style={{fontWeight:"bolder"}}>Общий промокод</Typography>
                    )}
                    {type === 1 && id !== "new" && (
                      <Typography  style={{fontWeight:"bolder"}}>Индивидуальный промокод</Typography>
                    )}
                    {type === 2 && id !== "new" && (
                      <Typography  style={{fontWeight:"bolder"}}>Персональный промокод</Typography>
                    )}

                    <br />
                  </div>
                </Grid>
                {type === 1 && id !== "new" && (
                  <Typography>
                    {" "}
                    Клиент
                    <Autocomplete
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ width: "400px" }}
                        />
                      )}
                      onChange={(e, newValue) => {
                        setPromoCodeForm((prev) => ({
                          ...prev,
                          customerId: newValue?.id || 0,
                        }));
                      }}
                      getOptionLabel={(option) => option.phoneNumber +", " + option.lastName}
                      renderOption={(option) => getClientName(option) ?? ""}
                      options={clients}
                      onInputChange={(event, newInputValue) => {
                        setClientSearchString(
                          newInputValue.replace(/[^a-zA-Z0-9а-яА-Я]/g, "")
                        );
                      }}
                      ListboxProps={{
                        style: { scrollbarWidth: "none" },
                        onScroll: throttle((event: React.SyntheticEvent) => {
                          const listNode = event.currentTarget;
                          if (
                            listNode.scrollTop + listNode.clientHeight ===
                            listNode.scrollHeight
                          ) {
                            setClientsPage((pages) =>
                              pages < 10 ? pages + 1 : pages
                            );
                          }
                        }),
                      }}
                      className={classes.clientSearch}
                    />
                  </Typography>
                )}
                <Grid container direction={"column"}>
                  <Grid
                    container
                    direction={"row"}
                    justify={"space-between"}
                    alignItems={"center"}
                  >
                    <div>
                      <Typography
                        component={"span"}
                        variant={"body1"}
                      ></Typography>
                    </div>
                  </Grid>
                </Grid>
                {type > 0 && id !== "new" && (
                  <Grid container direction={"column"}>
                    <Grid
                      direction={"row"}
                      justify={"space-between"}
                      className={
                        individual
                          ? classes.checkWithInput3
                          : classes.checkWithInput
                      }
                      alignItems={"center"}
                      container
                    >
                      {type === 2 && (
                        <div className={classes.codeWordForm}>
                          <Typography
                            component={"span"}
                            variant={"body1"}
                            className={classes.pointer}
                          >
                            <DatePicker
                                variant="inline"
                                format="DD.MM.YYYY"
                                margin="dense"
                                id="date-picker-inline"
                                label="Дата начала"
                              value={promoCodeForm.startDate}
                              onChange={(e) => {
                                setPromoCodeForm((prev) => ({
                                  ...prev,
                                  startDate: e,
                                }));
                              }}
                            />
                          </Typography>
                        </div>
                      )}
                    </Grid>
                    <Grid
                      direction={"row"}
                      justify={"space-between"}
                      className={
                        individual
                          ? classes.checkWithInput3
                          : classes.checkWithInput
                      }
                      alignItems={"center"}
                      container
                    >
                      {type === 2 && (
                        <div className={classes.codeWordForm}>
                          <Typography
                            component={"span"}
                            variant={"body1"}
                            className={classes.pointer}
                          >
                            <TextField
                              placeholder="Количество кодов"
                              onChange={(e) =>
                                setPromoCodeForm((prev) => ({
                                  ...prev,
                                  valuesCount: e.target.value,
                                }))
                              }
                            />
                          </Typography>
                        </div>
                      )}
                    </Grid>
                    <Grid
                      direction={"row"}
                      justify={"space-between"}
                      className={
                        individual
                          ? classes.checkWithInput3
                          : classes.checkWithInput
                      }
                      alignItems={"center"}
                      container
                    >
                      <div className={classes.codeWordForm}>
                        <div>
                          <Typography
                            component={"span"}
                            variant={"body1"}
                            className={classes.pointer}
                            onClick={() =>
                              setPromoCodeForm((prev) => ({
                                ...prev,
                                isRus: !prev.isRus,
                              }))
                            }
                          >
                            Буквы а-я
                          </Typography>
                          <Checkbox
                            checked={promoCodeForm.isRus}
                            color={"primary"}
                            onChange={(e) =>
                              setPromoCodeForm((prev) => ({
                                ...prev,
                                isRus: e.target.checked,
                              }))
                            }
                          />
                        </div>
                        <div>
                          <Typography
                            component={"span"}
                            variant={"body1"}
                            className={classes.pointer}
                            onClick={() =>
                              setPromoCodeForm((prev) => ({
                                ...prev,
                                isEng: !prev.isEng,
                              }))
                            }
                          >
                            Буквы a-z
                          </Typography>
                          <Checkbox
                            checked={promoCodeForm.isEng}
                            color={"primary"}
                            onChange={(e) =>
                              setPromoCodeForm((prev) => ({
                                ...prev,
                                isEng: e.target.checked,
                              }))
                            }
                          />
                        </div>
                        <div>
                          <Typography
                            component={"span"}
                            variant={"body1"}
                            className={classes.pointer}
                            onClick={() =>
                              setPromoCodeForm((prev) => ({
                                ...prev,
                                isNum: !prev.isNum,
                              }))
                            }
                          >
                            Цифры
                          </Typography>
                          <Checkbox
                            checked={promoCodeForm.isNum}
                            color={"primary"}
                            onChange={(e) =>
                              setPromoCodeForm((prev) => ({
                                ...prev,
                                isNum: e.target.checked,
                              }))
                            }
                          />
                        </div>
                        <Button
                          variant={"outlined"}
                          color={"primary"}
                          disabled={
                            ( ( promoCodeForm.isEng === false &&
                            promoCodeForm.isRus === false &&
                            promoCodeForm.isNum === false)) ||
                            (type === 1 && promoCodeForm.customerId === 0)
                            ||
                            (type === 2 && (promoCodeForm.valuesCount === "0" ||  promoCodeForm.startDate === "0"))
                          }
                          onClick={generatePromoCodeHandler}
                        >
                          Сгенерировать
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                )}
                {newPromoCodesList.length > 0 && (
                  <Alert className={classes.alertTitle} severity="info">
                    <AlertTitle ><div className={classes.alertTitleText}>Сгенерированные промокоды</div></AlertTitle>
                  </Alert>
                )}

                <Grid container direction={"column"}>
                  <Grid
                    container
                    direction={"row"}
                    justify={"space-between"}
                    className={classes.checkCasual}
                    alignItems={"center"}
                  >
                    {type === 0 && (
                      <TextField
                        value={code}
                        onChange={(e) =>
                          id === "new" &&
                          onChangeValue(() => setCode(e.target.value))
                        }
                        label={"код"}
                        required={!individual}
                        error={!code && isBlurred.code && !individual}
                        onBlur={() => setBlurredField("code")}
                      />
                    )}
                  </Grid>
                  <Divider/>
                  <Grid
                    container
                    direction={"row"}
                    justify={"space-between"}
                    className={classes.checkCasual}
                    alignItems={"center"}
                    onClick={() => onChangeValue(() => setForSite(!forSite))}
                  >
                    <Typography component={"span"} variant={"body1"}>
                      Для сайта:
                    </Typography>
                    <Checkbox
                      color={"primary"}
                      checked={forSite}
                      onChange={(e) =>
                        onChangeValue(() => setForSite(e.target.checked))
                      }
                    />
                  </Grid>
                  <Grid
                    container
                    direction={"row"}
                    justify={"space-between"}
                    className={classes.checkCasual}
                    alignItems={"center"}
                    onClick={() => onChangeValue(() => setForApp(!forApp))}
                  >
                    <Typography component={"span"} variant={"body1"}>
                      Для приложений:
                    </Typography>
                    <Checkbox
                      color={"primary"}
                      checked={forApp}
                      onChange={(e) =>
                        onChangeValue(() => setForApp(e.target.checked))
                      }
                    />
                  </Grid>
                  <Grid
                    container
                    direction={"row"}
                    justify={"space-between"}
                    alignItems={"center"}
                    className={classes.checkCasual}
                    onClick={() => onChangeValue(() => setDelivery(!delivery))}
                  >
                    <Typography component={"span"} variant={"body1"}>
                      При доставке:
                    </Typography>
                    <Checkbox
                      color={"primary"}
                      checked={delivery}
                      onChange={(e) =>
                        onChangeValue(() => setDelivery(e.target.checked))
                      }
                    />
                  </Grid>
                  <Grid
                    container
                    direction={"row"}
                    justify={"space-between"}
                    className={classes.checkCasual}
                    alignItems={"center"}
                    onClick={() => onChangeValue(() => setPickUp(!pickUp))}
                  >
                    <Typography component={"span"} variant={"body1"}>
                      При самовывозе:
                    </Typography>
                    <Checkbox
                      color={"primary"}
                      checked={pickUp}
                      onChange={(e) =>
                        onChangeValue(() => setPickUp(e.target.checked))
                      }
                    />
                  </Grid>
                  <Grid
                    container
                    direction={"row"}
                    justify={"space-between"}
                    className={classes.checkCasual}
                    alignItems={"center"}
                  >
                    <TextField
                      label={"Время жизни"}
                      disabled={isEdit(id)}
                      value={lifetime === null ? 0 : lifetime}
                      onBlur={() => setBlurredField("lifetime")}
                      onChange={(e) => {
                        if (!isNaN(Number(e.target.value))) {
                          onChangeValue(() =>
                            setLifeTime(
                              Number(e.target.value) < 19999999
                                ? Number(e.target.value)
                                : lifetime
                            )
                          );
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <Box className={classes.block}>
                <Typography variant={"h6"}>
                  Условия для использования:
                </Typography>
                <Grid container direction={"column"}>
                  <Grid
                    direction={"row"}
                    className={classes.checkCasual}
                    container
                    alignItems={"center"}
                    justify={"space-between"}
                  >
                    <Typography component={"span"} variant={"body1"}>
                      День рождения
                    </Typography>
                    <Checkbox
                      checked={birthDay}
                      color={"primary"}
                      onChange={(e) =>
                        onChangeValue(() => setBirthday(e.target.checked))
                      }
                    />
                  </Grid>
                  <Grid
                    container
                    direction={"row"}
                    justify={"space-between"}
                    className={classes.checkCasual}
                    alignItems={"center"}
                    onClick={() =>
                      onChangeValue(() => setFirstOrder(!firstOrder))
                    }
                  >
                    <Typography component={"span"} variant={"body1"}>
                      Первый заказ
                    </Typography>
                    <Checkbox
                      checked={firstOrder}
                      color={"primary"}
                      onChange={(e) =>
                        onChangeValue(() => setFirstOrder(e.target.checked))
                      }
                    />
                  </Grid>
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      variant={condition === "price" ? "contained" : "outlined"}
                      onClick={() =>
                        onChangeValue(() => {
                          setCondition("price");
                          setProductSets([]);
                        })
                      }
                    >
                      Минимальная цена
                    </Button>
                    <Button
                      onClick={() =>
                        onChangeValue(() => {
                          setCondition("goods");
                          setMinPrice(0);
                        })
                      }
                      variant={condition === "price" ? "outlined" : "contained"}
                    >
                      Товары в корзине
                    </Button>
                  </ButtonGroup>
                  {condition === "price" ? (
                    <Box className={classes.block}>
                      <Typography variant={"body1"}>
                        Минимальная сумма заказа
                      </Typography>
                      <TextField
                        value={minPrice || 0}
                        onChange={(e) => {
                          if (!isNaN(Number(e.target.value))) {
                            onChangeValue(() =>
                              setMinPrice(
                                Number(e.target.value) < 100000000
                                  ? Number(e.target.value)
                                  : minPrice
                              )
                            );
                          }
                        }}
                      />
                    </Box>
                  ) : (
                    <Box className={classes.block}>
                      <Grid container direction={"column"}>
                        {productSets.map((set: CodeProductSet, i: number) => {
                          return (
                            <Item
                              onChange={onSetChange}
                              key={i}
                              setIndex={i}
                              set={set}
                              promoCategories={[...promoCategories]}
                              products={products}
                              onDelete={deleteSet}
                            />
                          );
                        })}
                        <Button
                          variant={"outlined"}
                          color={"primary"}
                          onClick={() =>
                            onChangeValue(() =>
                              setProductSets([
                                ...productSets,
                                {
                                  productSetId: undefined,
                                  promotionCategoryId: null,
                                  productId: null,
                                  promoCodeId: id,
                                  count: 1,
                                  variantsList: [],
                                },
                              ])
                            )
                          }
                        >
                          Добавить
                        </Button>
                      </Grid>
                    </Box>
                  )}
                </Grid>
                {minPrice > 0 && productSets.length > 0 ? (
                  <Typography align="center" className={classes.error}>
                    Необходимо выбрать что-то одно, минимальную цену или товары
                    в корзине*
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
              <Divider />
              <Box className={classes.block}>
                <Typography
                  variant={"h6"}
                  className={
                    discount > 0 || presents.length > 0 || !isBlurred.condition
                      ? ""
                      : classes.error
                  }
                >
                  Подарки*
                </Typography>
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                >
                  <Button
                    variant={giftType === "discount" ? "contained" : "outlined"}
                    onClick={() => {
                      onChangeValue(() => setGiftType("discount"));
                      setBlurredField("condition");
                      setPresents([]);
                    }}
                  >
                    Скидка
                  </Button>
                  <Button
                    onClick={() => {
                      onChangeValue(() => setGiftType("prods"));
                      setBlurredField("condition");
                      setDiscount(0);
                    }}
                    variant={giftType === "discount" ? "outlined" : "contained"}
                  >
                    Товары
                  </Button>
                </ButtonGroup>
                <Grid container direction={"column"}>
                  <Box className={classes.block}>
                    {giftType === "discount" ? (
                      <Grid
                        direction={"row"}
                        className={classes.checkWithInput}
                        container
                        alignItems={"center"}
                        justify={"space-between"}
                      >
                        <Typography variant={"body1"} component={"span"}>
                          Размер скидки:
                        </Typography>
                        <TextField
                          value={discount || ""}
                          onBlur={() => setBlurredField("condition")}
                          onChange={(e) => {
                            if (!isNaN(Number(e.target.value))) {
                              onChangeValue(() =>
                                setDiscount(
                                  Number(e.target.value) < 100000000
                                    ? Number(e.target.value)
                                    : discount
                                )
                              );
                            }
                          }}
                        />
                      </Grid>
                    ) : (
                      <>
                        <Typography>Товары</Typography>
                        <Box className={classes.block}>
                          <Grid container direction={"column"}>
                            {presents.map(
                              (present: CodeProductSet, i: number) => {
                                return (
                                  <Item
                                    onChange={onPresentChange}
                                    key={i}
                                    setIndex={i}
                                    set={present}
                                    promoCategories={[...promoCategories]}
                                    products={products}
                                    onDelete={deletePresents}
                                  />
                                );
                              }
                            )}
                            <Button
                              variant={"outlined"}
                              color={"primary"}
                              onClick={() =>
                                onChangeValue(() =>
                                  setPresents([
                                    ...presents,
                                    {
                                      productSetId: undefined,
                                      promotionCategoryId: null,
                                      productId: null,
                                      promoCodeId: id,
                                      count: 1,
                                      variantsList: [],
                                    },
                                  ])
                                )
                              }
                            >
                              Добавить
                            </Button>
                          </Grid>
                        </Box>
                      </>
                    )}
                  </Box>
                </Grid>
                {discount > 0 && presents.length > 0 ? (
                  <Typography align="center" className={classes.error}>
                    Необходимо выбрать что-то одно, скидку или товары*
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            </Paper>
          </Container>
        </DialogContent>
        <DialogActions>
          {alerted ? (
            <ModalCloseConfirm
              cancel={() => clearFieldAndClose()}
              hideAlert={() => setAlerted(false)}
            />
          ) : (
            <Box className={classes.block}>
              <ActionGroup
                isDisable={!isValid}
                save={save}
                cancel={() => clearFieldAndClose()}
              />
            </Box>
          )}
        </DialogActions>
        {progress && <ProgressBar bottom />}
      </Dialog>
    );
  }
};

export default PromocodesForm;
