import { makeStyles } from "@material-ui/styles";
import {
  OrderCommonPromotionItem,
  OrderPromotionProductSetsList,
} from "../../_shared/types";
import {
  copyObject,
  getMonthNameByNumber,
  round15MinutesMoment,
} from "../../_shared/utils";
import moment, { Moment } from "moment/moment";

export const useStyles = makeStyles({
  darkerDisabled: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.8)", // (default alpha is 0.38)
    },
  },
  ingredients: {
    color: "#f44336",
    fontWeight: 500,
  },
  backdrop: {
    zIndex: 1,
    color: "#fff",
  },
  disabledColor: {
    color: "rgba(0, 0, 0, 0.26);",
  },
  paymentType: {
    fontWeight: "bold",
    color: "#4f6228",
  },
  deliveryAddress: {
    borderRadius: 16,
    display: "inline-flex",
    fontSize: 14,
    fontWeight: 500,
    alignItems: "flex-start",
  },
  readyTime: {
    display: "flex",
    alignItems: "center",
    "& > span:first-child": {
      display: "flex",
      alignItems: "center",
      color: "#632423",
    },
  },
  promotionName: {
    fontWeight: 500,
  },
  promotionItemName: {
    paddingLeft: 30,
  },
  shopSelect: { marginBottom: 5 },
  tableTitle: {
    "& > p": {
      margin: "0 !important",
    },
    fontSize: 16,
    padding: 16,
    paddingBottom: 5,
    display: "flex",
    alignItems: "center",
    "& > svg:hover": {
      cursor: "pointer",
    },
    "& span": {
      display: "flex",
      alignItems: "center",
    },
  },
  radioGroup: {
    color: "#632423",
    display: "flex",
    flexDirection: "row",
  },
  readyTimeBlock: {
    display: "flex",
    alignItems: "center",
  },
  requiredField: {
    color: "#f44336",
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    lineHeight: "1.66",
  },
  transparent: {
    opacity: 0,
    width: 0,
    height: 0,
  },
  adminCommentary: {
    display: "flex",
    alignItems: "flex-start",
    "& > span:nth-child(2):hover": { cursor: "pointer" },
  },
  productsSubmitBtn: { margin: 10 },
  deleteCell: {
    padding: 0,
    paddingRight: 10,
  },
  counterCell: {
    padding: 0,
    minWidth: 95,
    maxWidth: 95,
  },
  weightPriceWrapper: { width: "35%", display: "flex", alignItems: "center" },
  bold: { fontWeight: "bold" },
  pointer: { "& > span:hover": { cursor: "pointer" } },
  ProductsInfoBlock: {
    marginBottom: 24,
  },
  MainInfoBlock: { marginBottom: 24 },
  paper: { padding: 20 },
  dialogContent: {
    backgroundColor: "#fafafa",
    scrollbarWidth: "none",
  },
  dialogTitle: { fontWeight: 500, fontSize: 20, marginTop: 0 },
  colorRed: {
    color: "#f44336",
  },
  accordion: { width: "48%", "&:before": { display: "none" } },
  accordionWrapper: {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
  },
  accordionDetails: {
    display: "block",
  },
  dialogActions: { padding: 0, justifyContent: "space-between" },
  accordionSummary: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingRight: "3%",
    "& > span:nth-child(2)": {
      fontSize: 15,
      fontWeight: 600,
      color: "darkseagreen",
    },
  },
  fullWidth: {
    width: "100%",
    display: "flex",
    "& > div": {
      marginRight: 8,
      width: "100%",
      "&:last-child": { marginRight: 0 },
    },
  },
  categoryRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: 15,
  },
  alert: {
    width: "100%",
  },
  OptionName: {
    width: "65%",
    display: "flex",
    paddingRight: "4%",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 500,
  },
  priceField: { width: "30%", marginRight: "auto" },
  weightField: { width: "30%", marginRight: "5%" },
  categoryName: {
    width: "65%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 500,
  },
  formControl: {
    minWidth: 120,
  },
  wrapper: {
    width: "100%",
    display: "inline",
  },
  mt20: { marginTop: 20 },
  categoriesWrapper: {
    marginTop: 25,
    width: "100%",
    "& > div": {
      display: "flex",
      width: "100%",
    },
  },
  orderHeader: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 18,
  },
  client: { fontSize: 20, fontWeight: 500 },
  label: {
    minHeight: 32,
    borderRadius: 16,
    display: "inline-flex",
    padding: "7px 12px",
    fontSize: 14,
    fontWeight: 500,
    alignItems: "center",
  },
  labelCreated: { backgroundColor: "#90caf9" },
  labelConfirmed: { backgroundColor: "#a5d6a7" },
  labelProcess: { backgroundColor: "#ffab91" },
});

// подходит ли товар сету
const isSetSuitable = (set: any, item: any) =>
  set.productIds.includes(item.productId) &&
  set.promotionCategoryId === item.promotionCategoryId &&
  (item.options && set.variantIds.length > 0
    ? set.variantIds.includes(+Object.keys(item.options)[0])
    : true);
// добавляем товар в какой-либо из сетов, если никуда не подходит, добавляем в любой, возвращаем новый массив сетов
export const addProductToProductSet = (
  item: OrderCommonPromotionItem,
  quantityLeft: number,
  expectedSetsList: OrderPromotionProductSetsList[],
  currentSetsList: OrderPromotionProductSetsList[],
  changedList: boolean[]
) => {
  const newChangedList = copyObject(changedList);
  let newCurrentSetsList = copyObject(currentSetsList);
  let currentItemQuantity = quantityLeft;
  // если из всех сетов подходит лишь один, то добавляем в него весь текущий товар
  if (expectedSetsList.filter((el) => isSetSuitable(el, item)).length === 1) {
    // ищем этот подходящий сет и добавляем кол-во
    expectedSetsList.find((el, index) => {
      let result = false;
      if (isSetSuitable(el, item) && !newChangedList[index]) {
        newCurrentSetsList[index].count =
          newCurrentSetsList[index].count + currentItemQuantity;
        result = true;
      }
      return result;
    });
  } else {
    // иначе, если есть несколько подходящих сетов
    let curSetIndex = undefined;
    let isAlreadyFind = false;
    let allSetsFilled = true;
    expectedSetsList.filter((el, index) => {
      if (isAlreadyFind) return true;
      let result = false;
      if (
        isSetSuitable(el, item) &&
        !newChangedList[index] &&
        newCurrentSetsList[index].count !== expectedSetsList[index].count
      ) {
        allSetsFilled = false;
        isAlreadyFind = true;
        result = true;
        curSetIndex = index;
      }
      return result;
    });
    if (!allSetsFilled) {
      if (!curSetIndex) curSetIndex = 0;
      // если count в продукте <= ожидаемого count, то делаем, что и раньше,
      // добавляем count в этот сет
      if (currentItemQuantity <= expectedSetsList[curSetIndex].count) {
        newCurrentSetsList[curSetIndex].count =
          newCurrentSetsList[curSetIndex].count + currentItemQuantity;
      } else {
        // иначе добавляем только то, что <= ожидаемого, а с излишком идем дальше
        const addedCount =
          expectedSetsList[curSetIndex].count -
          newCurrentSetsList[curSetIndex].count;
        newCurrentSetsList[curSetIndex].count =
          newCurrentSetsList[curSetIndex].count + addedCount;
        currentItemQuantity = currentItemQuantity - addedCount;
        let changedListProp = copyObject(newChangedList);
        changedListProp[curSetIndex] = true;
        // надо заново найти сет и снова проверять, один ли он и т.д.
        newCurrentSetsList = addProductToProductSet(
          item,
          currentItemQuantity,
          expectedSetsList,
          newCurrentSetsList,
          changedListProp
        );
      }
    }
  }
  return newCurrentSetsList;
};

export const getDeliveryIntervals = () => {
  const min = moment().minutes(105).toDate();
  const max = moment()
    .hours(24 * 7)
    .toDate();
  return [{ min, max }];
};

export const getShopIntervalsForWeek = (
  workingDays: any,
  type: "delivery" | "pickup"
) => {
  const now = moment();
  const currentWeekday = now.isoWeekday();

  let tts: Array<Array<Moment>> = [];

  const dates: Array<Moment> = [];
  for (let i = 0; i < 7; i++) {
    const t = moment();
    t.add(i, "days");
    dates.push(moment(t));
  }

  Object.entries(workingDays).map((entry: any) => {
    const timeFrom = moment(entry[1].workingTime[0].timeFrom);
    const timeTill = moment(entry[1].workingTime[0].timeTill);
    const dayOfWeek = entry[1].dayOfWeek === 0 ? 7 : entry[1].dayOfWeek;
    let step = moment(timeFrom);

    const day = dates
      .find((d: Moment) => d.isoWeekday() === dayOfWeek)!
      .dayOfYear();

    if (timeFrom.isoWeekday() === timeTill.isoWeekday()) {
      step.dayOfYear(day);
      timeTill.dayOfYear(day);
    } else {
      step.dayOfYear(day);
      timeTill.dayOfYear(day + 1);
    }

    if (
      step.isoWeekday() !== currentWeekday ||
      (step.isoWeekday() === currentWeekday && now <= step)
    ) {
      if (type === "pickup") {
        step.add(15, "minutes");
      } else {
        step.add(46, "minutes");
      }
    }
    if (step.isoWeekday() === currentWeekday) {
      if (now > step) {
        step = moment();
        if (type === "delivery") {
          step.add(60, "minutes");
        } else {
          step.add(20, "minutes");
        }
        // timeTill.add(1, "days");
      }
    }
    round15MinutesMoment(step);
    const times = [];

    // const isTakeAwayAndClosedNow =
    //   type === "pickup" && new Date().getTime() < timeFrom.toDate().getTime();

    while (
      step.toDate() < timeTill.toDate() &&
      ((step.isoWeekday() === currentWeekday &&
        step > timeFrom &&
        step < timeTill) ||
        step.isoWeekday() !== currentWeekday)
    ) {
      times.push(moment(step.set("seconds", 0).set("milliseconds", 0)));
      step.add(15, "minutes");
    }

    if (
      type === "pickup" &&
      times.length === 0 &&
      new Date().getTime() > timeFrom.toDate().getTime() &&
      new Date().getTime() < timeTill.toDate().getTime()
    )
      times.push(moment(timeTill));

    if (
      times.length &&
      times[times.length - 1].minutes() !== moment(timeTill).minutes()
    )
      times.push(moment(timeTill));

    if (type === "pickup") {
      if (
        times.length &&
        moment(times[times.length - 1]).hours() !== moment(timeTill).hours() &&
        moment(times[times.length - 1]).minutes() !== moment(timeTill).minute()
      )
        times.push(moment(timeTill));
    }

    tts.push(times);
  });

  tts = tts.filter((el) => !!el[0]?.dayOfYear());

  tts.sort((a: Array<Moment>, b: Array<Moment>) => {
    if (a[0].dayOfYear() < b[0].dayOfYear()) {
      return -1;
    } else {
      return 1;
    }
  });

  const flatted = tts.flat();

  const selectTimes: { [key: string]: Array<Date> } = {};

  flatted.map((t: any) => {
    const mDate = moment(t);
    const key = `${mDate.date()} ${getMonthNameByNumber(mDate.month())}`;
    if (selectTimes[key]) {
      selectTimes[key].push(mDate.toDate());
    } else {
      selectTimes[key] = [mDate.toDate()];
    }
  });

  for (let key of Object.keys(selectTimes)) {
    selectTimes[key] = selectTimes[key].filter(
      (el, index, self) =>
        self.map((el) => el.getTime()).indexOf(el.getTime()) === index
    );
  }

  return selectTimes;
};

export const getShopIntervals = (
  receivingType: string,
  shops: any[],
  shop: { name: string; id: number }
) => {
  // берем детальную инфу о пиццерии
  const selectedShop = shops.find((el) => el.id === shop.id)!;
  if (receivingType === "pickup" && !selectedShop.isLocalPickup) return [];

  const workingDays =
    selectedShop[
      receivingType === "delivery" ? "deliveryWorking" : "pickupWorking"
      // @ts-ignore
    ].workingDays;
  // получаем времена режима работы сегодня
  let today = new Date();
  let currentDayWorkingTime = workingDays.find(
    (el: any) => el.dayOfWeek === today.getDay()
  )!.workingTime;
  let timeFromToday = new Date(currentDayWorkingTime[0].timeFrom);
  let timeTillToday = new Date(currentDayWorkingTime[0].timeTill);
  timeFromToday.setMonth(today.getMonth());
  timeTillToday.setMonth(today.getMonth());
  if (timeFromToday.getDate() === timeTillToday.getDate()) {
    timeFromToday.setDate(today.getDate());
    timeTillToday.setDate(today.getDate());
  } else {
    timeFromToday.setDate(today.getDate());
    timeTillToday.setDate(
      new Date(today.getTime() + 24 * 60 * 60 * 1000).getDate()
    );
    timeTillToday.setMonth(
      new Date(today.getTime() + 24 * 60 * 60 * 1000).getMonth()
    );
  }
  // получаем времена режима работы завтра
  let tomorrow = new Date();
  tomorrow.setTime(today.getTime() + 24 * 60 * 60 * 1000);
  currentDayWorkingTime = workingDays.find(
    (el: any) => el.dayOfWeek === tomorrow.getDay()
  )!.workingTime;
  const timeFromTomorrow = new Date(currentDayWorkingTime[0].timeFrom);
  const timeTillTomorrow = new Date(currentDayWorkingTime[0].timeTill);
  timeFromTomorrow.setMonth(tomorrow.getMonth());
  timeTillTomorrow.setMonth(tomorrow.getMonth());
  if (timeFromTomorrow.getDate() === timeTillTomorrow.getDate()) {
    timeFromTomorrow.setDate(tomorrow.getDate());
    timeTillTomorrow.setDate(tomorrow.getDate());
  } else {
    timeFromTomorrow.setDate(tomorrow.getDate());
    timeTillTomorrow.setDate(
      new Date(tomorrow.getTime() + 24 * 60 * 60 * 1000).getDate()
    );
    timeTillTomorrow.setMonth(
      new Date(tomorrow.getTime() + 24 * 60 * 60 * 1000).getMonth()
    );
  }
  // получаем времена режима работы вчера
  let yesterday = new Date();
  yesterday.setTime(today.getTime() - 24 * 60 * 60 * 1000);
  currentDayWorkingTime = workingDays.find(
    (el: any) => el.dayOfWeek === yesterday.getDay()
  )!.workingTime;
  const timeFromYesterday = new Date(currentDayWorkingTime[0].timeFrom);
  const timeTillYesterday = new Date(currentDayWorkingTime[0].timeTill);
  timeFromYesterday.setMonth(yesterday.getMonth());
  timeTillYesterday.setMonth(yesterday.getMonth());
  if (timeFromYesterday.getDate() === timeTillYesterday.getDate()) {
    timeFromYesterday.setDate(yesterday.getDate());
    timeTillYesterday.setDate(yesterday.getDate());
  } else {
    timeFromYesterday.setDate(yesterday.getDate());
    timeTillYesterday.setDate(
      new Date(yesterday.getTime() + 24 * 60 * 60 * 1000).getDate()
    );
    timeTillYesterday.setMonth(
      new Date(yesterday.getTime() + 24 * 60 * 60 * 1000).getMonth()
    );
  }
  const checkIfTimeInAnyIntervals = (time: Date) =>
    (time.getTime() >= timeFromYesterday.getTime() &&
      time.getTime() <= timeTillYesterday.getTime()) ||
    (time.getTime() >= timeFromToday.getTime() &&
      time.getTime() <= timeTillToday.getTime()) ||
    (time.getTime() >= timeFromTomorrow.getTime() &&
      time.getTime() <= timeTillTomorrow.getTime());

  timeFromYesterday.setSeconds(0);
  timeTillYesterday.setSeconds(0);
  timeFromToday.setSeconds(0);
  timeTillToday.setSeconds(0);
  timeFromTomorrow.setSeconds(0);
  timeTillTomorrow.setSeconds(0);

  let newMinMaxIntervals: { min: Date; max: Date }[] = [];
  //////////////////////////////////////////////////////////////////
  if (receivingType === "pickup") {
    // если круглосуточный самовывоз, то просто берем интервалы от текущего времени +20 мин и на ближайшие сутки
    if (selectedShop.isAroundTheClockPickup) {
      timeFromToday.setTime(today.getTime() + 20 * 60 * 1000);
      timeFromToday = getNearMultipleOf15(timeFromToday);
      timeTillToday.setTime(today.getTime() + 24 * 60 * 60 * 1000);
      timeFromToday.setSeconds(0);
      timeTillToday.setSeconds(0);
      if (timeFromToday.getTime() <= timeTillToday.getTime())
        newMinMaxIntervals = [
          {
            min: timeFromToday,
            max: timeTillToday,
          },
        ];
    } else {
      today.setTime(today.getTime() + 20 * 60 * 1000);
      // если самовывоз не круглосуточный, то находим подходящий режим работы (вчера/сегодня/завтра) и устанавливаем в нем from, как текущее время
      let scheduleIndex =
        timeFromYesterday.getTime() <= today.getTime() &&
        today.getTime() <= timeTillYesterday.getTime()
          ? 0
          : timeTillYesterday.getTime() <= today.getTime() &&
            today.getTime() <= timeTillToday.getTime()
          ? 1
          : timeTillToday.getTime() <= today.getTime() &&
            today.getTime() <= timeTillTomorrow.getTime()
          ? 2
          : undefined;
      let min =
        scheduleIndex === 0
          ? timeFromYesterday
          : scheduleIndex === 1
          ? timeFromToday
          : timeFromTomorrow;
      if (today.getTime() > min.getTime()) min.setTime(today.getTime());
      else min.setTime(min.getTime() + 20 * 60 * 1000);
      min = getNearMultipleOf15(min);
      const max =
        scheduleIndex === 0
          ? timeTillYesterday
          : scheduleIndex === 1
          ? timeTillToday
          : timeTillTomorrow;
      // устанавливаем min как конец рабочего дня
      if (min.getTime() >= max.getTime()) min.setTime(max.getTime());
      min.setSeconds(0);
      max.setSeconds(0);
      if (scheduleIndex !== undefined && min.getTime() <= max.getTime()) {
        newMinMaxIntervals = [
          {
            min,
            max,
          },
        ];
      }
    }
  } else {
    // если круглосуточная доставка, то просто берем интервалы от текущего времени +1 час и на ближайшие сутки
    if (selectedShop.isAroundTheClockDelivery) {
      timeFromToday.setTime(today.getTime() + 60 * 60 * 1000);
      timeTillToday.setTime(today.getTime() + 24 * 60 * 60 * 1000);
      timeFromToday.setSeconds(0);
      timeTillToday.setSeconds(0);
      timeFromToday = getNearMultipleOf15(timeFromToday);
      if (timeFromToday.getTime() <= timeTillToday.getTime()) {
        newMinMaxIntervals = [
          {
            min: timeFromToday,
            max: timeTillToday,
          },
        ];
      }
    } else {
      let startTime = today;
      let endTime = new Date(today);
      startTime.setTime(startTime.getTime() + 60 * 60 * 1000);
      endTime.setTime(endTime.getTime() + 24 * 60 * 60 * 1000);
      startTime.setSeconds(0);
      endTime.setSeconds(0);
      let min: null | Date = null;
      let max: null | Date = null;
      // проходим по всему интервалу от "сейчас" до следующих суток". проходим каждую минуту
      while (startTime.getTime() <= endTime.getTime()) {
        // если текущее число вошло в любой из графиков, то берем это число, как min
        if (checkIfTimeInAnyIntervals(startTime) && min === null)
          min = new Date(startTime);
        // если текущее число не вошло в графики, но min уже задан, то это будет max;
        if (
          (!checkIfTimeInAnyIntervals(startTime) ||
            endTime.getTime() - startTime.getTime() <= 1000 * 60) &&
          min !== null
        ) {
          min = getNearMultipleOf15(min);
          max = new Date(startTime);
          if (min.getTime() <= max.getTime()) {
            newMinMaxIntervals = [
              ...newMinMaxIntervals,
              {
                min,
                max,
              },
            ];
            min = null;
            max = null;
          }
        }
        startTime.setTime(startTime.getTime() + 60 * 1000);
      }
    }
  }
  for (let i = 0; i <= 6; i++) {
    let weekDay = moment(today).add(i, "day").isoWeekday();
    if (weekDay === 7) weekDay = 0;
    let dayTime = workingDays.find((el: any) => el.dayOfWeek === weekDay)
      .workingTime;
    const min = new Date(dayTime[0].timeFrom);
    const max = new Date(dayTime[0].timeTill);
    dayTime = {
      min: new Date(
        min.setTime(
          min.getTime() +
            (receivingType === "delivery" ? 1 + i : i) * 24 * 60 * 60 * 1000
        )
      ),
      max: new Date(
        max.setTime(
          max.getTime() +
            (receivingType === "delivery" ? 1 + i : i) * 24 * 60 * 60 * 1000
        )
      ),
    };
    newMinMaxIntervals.push(dayTime);
  }
  return newMinMaxIntervals;
};

export const getNearMultipleOf15 = (date: Date) => {
  let currentDate = new Date(date);
  while (currentDate.getMinutes() % 15 !== 0) {
    currentDate.setTime(currentDate.getTime() + 1000 * 60);
  }
  return currentDate;
};

const queryData = {
  phone: {
    ColumnName: "CustomerPhoneNumber",
    FilterOption: 3,
  },
  status: {
    ColumnName: "StatusInt",
    FilterOption: 11,
  },
  client: {
    ColumnName: "phoneNumber",
    FilterOption: 3,
  },
  lastActiveFrom: {
    ColumnName: "LastActive",
    FilterOption: 8,
  },
  lastActiveTo: {
    ColumnName: "LastActive",
    FilterOption: 10,
  },
};

export const getPromoValuesQuery = (
  id: number,
  searchString: string,
  page: number,
  rowsPerPage: number
) => {
  let result = `/promoCode/${id}/values?PageNumber=${page}&PageSize=${rowsPerPage}`;
  /*if (searchString.length > 3) {
    result += `&FilterParams[0].ColumnName=customerPhoneNumber&FilterParams[0].FilterOption=3&FilterParams[0].filterValue=${searchString}`;
  }*/
  return result;
};

export const getOrdersQuery = (
  clientSearch: string,
  ordersTypeSearch: number[],
  page: number,
  rowsPerPage: number,
  type: string,
  orderSearch: string
) => {
  let result = "";
  // filter: [phone, status]
  let filters = [false, false];
  if (clientSearch.length > 3) filters[0] = true;
  if (ordersTypeSearch.reduce((sum, acc) => sum + acc, 0) > 0)
    filters[1] = true;
  filters.map((filter, index) => {
    if (filter) {
      let currentFilterBy = index === 0 ? queryData.phone : queryData.status;
      if (index === 0) {
        let i = orderSearch.length ? 1 : 0;
        let c = currentFilterBy.ColumnName;
        let o = currentFilterBy.FilterOption;
        let v = clientSearch;
        result += `FilterParams[${i}].ColumnName=${c}&FilterParams[${i}].FilterOption=${o}&FilterParams[${i}].filterValue=${v}`;
      } else {
        let counter = 0;
        if (filters[0]) {
          counter++;
          result += "&";
        }
        let i, c, o, v;
        let addition: string[] = [];
        ordersTypeSearch.map((el: number, index: number) => {
          if (el === 1) {
            i = orderSearch.length ? counter + 1 : counter;
            c = currentFilterBy.ColumnName;
            o = currentFilterBy.FilterOption;
            v = type === "current" ? index : index + 3;
            addition.push(
              `FilterParams[${i}].ColumnName=${c}&FilterParams[${i}].FilterOption=${o}&FilterParams[${i}].filterValue=${v}`
            );
            counter++;
          }
          return true;
        });
        result += addition.join("&");
      }
    }
    return true;
  });
  let pre = `/orders/${type}?PageNumber=${page}&PageSize=${rowsPerPage}`;
  if (orderSearch.length) {
    const i = 0;
    pre += `&FilterParams[${i}].ColumnName=id&FilterParams[${i}].FilterOption=11&FilterParams[${i}].filterValue=${orderSearch}`;
  }
  if (filters.find((el) => el)) pre += "&";
  result = pre + result;
  return result;
};

export const getClientsQuery = (
  clientSearch: string,
  page: number,
  rowsPerPage: number,
  lastActive: { lastActiveFromFilter: Date; lastActiveToFilter: Date } | null
) => {
  let result = "";
  if (clientSearch.length) {
    let i = 0;
    let c = queryData.client.ColumnName;
    let o = queryData.client.FilterOption;
    let v = clientSearch;
    result += `&FilterParams[${i}].ColumnName=${c}&FilterParams[${i}].FilterOption=${o}&FilterParams[${i}].filterValue=${v}`;
  }
  if (lastActive) {
    let fromISO =
      lastActive.lastActiveFromFilter.toISOString().slice(0, -13) + "00:00:00";
    let toISO =
      lastActive.lastActiveToFilter.toISOString().slice(0, -13) + "23:59:59";
    let i = clientSearch.length ? 1 : 0;
    let c = queryData.lastActiveFrom.ColumnName;
    let o = queryData.lastActiveFrom.FilterOption;
    let v = fromISO;
    result += `&FilterParams[${i}].ColumnName=${c}&FilterParams[${i}].FilterOption=${o}&FilterParams[${i}].filterValue=${v}`;
    i++;
    c = queryData.lastActiveTo.ColumnName;
    o = queryData.lastActiveTo.FilterOption;
    v = toISO;
    result += `&FilterParams[${i}].ColumnName=${c}&FilterParams[${i}].FilterOption=${o}&FilterParams[${i}].filterValue=${v}`;
  }
  let pre = `/customers?PageNumber=${page}&PageSize=${rowsPerPage}`;
  result = pre + result;
  return result;
};

export const getTimeFromString = (data: {
  value: string;
  isToday: boolean;
}) => {
  if (data.value === "как можно быстрее") {
    return new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  }
  const str = data.value;
  const today = new Date();
  const hours = +str.slice(-5, -3);
  const minutes = +str.slice(-2, str.length);
  let result = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    hours + 3,
    minutes,
    0
  );
  if (!data.isToday) result.setTime(result.getTime() + 24 * 60 * 60 * 1000);
  return result;
};

export const createVariantLabel = (variant: {
  variantId: number;
  values: Array<{
    optionId: number;
    optionData: string;
    optionValueId: number;
    optionValueData: string;
  }>;
}) => {
  return `${variant.values
    .map((v) => v.optionData + ": " + v.optionValueData)
    .join(" ")}`;
};
