import React, { FC, useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";
import {
  AlertContextType,
  Employee,
  EmployeeEdit,
  UserCookie,
} from "../../../_shared/types";
import FormHelperText from "@material-ui/core/FormHelperText";
import API from "../../../_shared/axios";
import { AlertContext } from "../../_shared/ToastList";
import {
  alertError,
  getErrorMsg,
  isObjectsEqual,
} from "../../../_shared/utils";
import { ProgressBar } from "../../_shared/ProgressBar";
import cookies from "../../../../cookies";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
  },
  opacity1: { opacity: 1 },
  opacity0: { opacity: 0 },
  colorInactive: { color: "rgba(0, 0, 0, 0.54)" },
  colorError: { color: "#f44336" },
  colorBlack: { color: "rgba(0, 0, 0, 0.87)" },
  mt20: { marginTop: 20 },
  wrapper: {
    display: "inline",
    marginLeft: 40,
  },
}));

const EditEmployeeForm: FC<{
  employeeId?: number;
  onEmployeeEdit: (id: number, employee: EmployeeEdit) => Promise<any>;
  onClose: () => void;
}> = ({ employeeId, onEmployeeEdit, onClose }) => {
  const employeeInit = {
    lastName: "",
    firstName: "",
    patronymic: "",
    roleId: 1,
    generateNewLogin: false,
  };
  const [employee, setEmployee] = useState(employeeInit);
  const [oldEmployee, setOldEmployee] = useState(employeeInit);

  const [isRoleBlurred, setIsRoleBlurred] = useState(false);
  const [progress, setProgress] = useState(false);

  const alertContext = useContext<AlertContextType>(AlertContext);

  useEffect(() => {
    if (employeeId) {
      setOldEmployee({} as EmployeeEdit);
      API.get(`/employees/${employeeId}`)
        .then(({ data }: { data: Employee }) => {
          const { lastName, firstName, patronymic, roles } = data;
          const newEmployee = {
            lastName,
            firstName,
            patronymic,
            roleId:
              roles[0] === "SuperAdmin" ? 1 : roles[0] === "Admin" ? 2 : 3,
            generateNewLogin: false,
          };
          setEmployee(newEmployee);
          setOldEmployee(newEmployee);
          setAlerted(false);
        })
        .catch((error) =>
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка получения сотрудника")
          )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);

  const classes = useStyles();

  const isFIOChanged = () =>
    employee.lastName !== oldEmployee.lastName ||
    employee.firstName !== oldEmployee.firstName ||
    employee.patronymic !== oldEmployee.patronymic;

  const requiredFieldEmpty = () =>
    employee.lastName === "" ||
    employee.firstName === "" ||
    employee.patronymic === "";

  const [alerted, setAlerted] = useState(false);

  const user: UserCookie | undefined = cookies.get("user");

  return (
    <Dialog
      disableBackdropClick
      onBackdropClick={() =>
        progress
          ? null
          : isObjectsEqual(employee, oldEmployee)
          ? onClose()
          : setAlerted(true)
      }
      open={!!employeeId}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Редактирование сотрудника
      </DialogTitle>
      <DialogContent>
        <form>
          <TextField
            autoFocus
            margin="dense"
            id="lastName"
            label="Фамилия"
            value={employee.lastName}
            onChange={(e) =>
              setEmployee({ ...employee, lastName: e.target.value })
            }
            type="text"
            fullWidth
            error={!employee.lastName}
            helperText={!employee.lastName ? "Обязательное поле" : ""}
            required
          />
          <TextField
            margin="dense"
            id="firstName"
            label="Имя"
            value={employee.firstName}
            onChange={(e) =>
              setEmployee({ ...employee, firstName: e.target.value })
            }
            type="text"
            fullWidth
            error={!employee.firstName}
            helperText={!employee.firstName ? "Обязательное поле" : ""}
            required
          />
          <TextField
            margin="dense"
            id="patronymic"
            label="Отчество"
            value={employee.patronymic}
            onChange={(e) =>
              setEmployee({ ...employee, patronymic: e.target.value })
            }
            type="text"
            fullWidth
            error={!employee.patronymic}
            helperText={!employee.patronymic ? "Обязательное поле" : ""}
            required
          />
          <FormControl
            fullWidth
            component="fieldset"
            className={[classes.formControl, classes.mt20].join(" ")}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={employee.roleId}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              onBlur={() => setIsRoleBlurred(true)}
              error={employee.roleId === 0 && isRoleBlurred}
              defaultValue={employee.roleId}
              required
              onChange={(e: any) =>
                setEmployee({ ...employee, roleId: e.target.value })
              }
              className={
                employee.roleId === 0 && !isRoleBlurred
                  ? classes.colorInactive
                  : employee.roleId === 0 && isRoleBlurred
                  ? classes.colorError
                  : classes.colorBlack
              }
            >
              <MenuItem value={0} disabled>
                Роль *
              </MenuItem>
              <MenuItem value={1}>Суперадминистратор</MenuItem>
              <MenuItem value={2}>Администратор</MenuItem>
              <MenuItem value={3}>Оператор</MenuItem>
              <MenuItem value={5}>Куръер</MenuItem>
            </Select>
            <FormHelperText
              className={[
                classes.colorError,
                employee.roleId === 0 && isRoleBlurred
                  ? classes.opacity1
                  : classes.opacity0,
              ].join(" ")}
            >
              Обязательное поле
            </FormHelperText>
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <FormControlLabel
              disabled={!isFIOChanged()}
              control={
                <Checkbox
                  defaultChecked={false}
                  onChange={() =>
                    setEmployee({
                      ...employee,
                      generateNewLogin: !employee.generateNewLogin,
                    })
                  }
                  name="checkedB"
                  color="primary"
                />
              }
              label="Сгенерировать новый логин из ФИО"
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        {alerted ? (
          <Alert
            severity="warning"
            action={
              <>
                <Button color="inherit" size="small" onClick={() => onClose()}>
                  Да
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setAlerted(false)}
                >
                  Нет
                </Button>
              </>
            }
          >
            Вы действительно хотите отменить все изменения и выйти? Введенные
            данные будут утеряны
          </Alert>
        ) : (
          <>
            <Button onClick={onClose} color="primary" disabled={progress}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                setProgress(true);
                onEmployeeEdit(employeeId ? employeeId : 1, {
                  ...employee,
                  cityId: user?.city.id,
                })
                  .then(onClose)
                  .catch((error) => console.log(error))
                  .finally(() => setProgress(false));
              }}
              color="primary"
              type="submit"
              disabled={
                requiredFieldEmpty() ||
                isObjectsEqual(employee, oldEmployee) ||
                progress
              }
            >
              Подтвердить
            </Button>
          </>
        )}
      </DialogActions>
      {progress && <ProgressBar bottom />}
    </Dialog>
  );
};

export default EditEmployeeForm;
