import React, { FC, useState } from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { DispatcherProduct, ProductRow } from "../../../../_shared/types";
import OptionSelect from "../OptionSelect";
import Options from "./options";

const useStyles = makeStyles({
  productItem: {
    width: "300px",
    flexShrink: 0,
    padding: "20px",
    margin: "5px",
  },
  selectedProd: {
    border: "solid 1px #F50057",
    borderRadius: "10px",
  },
  thumbnail: {
    width: "95px",
    height: "95px",
  },
  itemControl: {
    marginTop: "10px",
    padding: "5px",
  },
});

const ProductItem: FC<{
  selected?: boolean;
  setSelectedProduct: (id: number) => void;
  product: ProductRow;
  addItem: (id: number, variantId?: number) => void;
  addCount: (id: number, variantId?: number) => void;
  removeCount: (id: number, variantId?: number) => void;
}> = ({
  selected,
  setSelectedProduct,
  product,
  addCount,
  addItem,
  removeCount,
}) => {
  const classes = useStyles();

  const [variant, setVariant] = useState<number>();

  return (
    <Paper
      className={
        selected
          ? [classes.selectedProd, classes.productItem].join(" ")
          : classes.productItem
      }
      elevation={3}
    >
      <Grid
        key={product.id}
        container
        wrap={"wrap"}
        direction={"row"}
        justify={"center"}
        alignItems="center"
        spacing={3}
        onClick={() => setSelectedProduct(product.id)}
      >
        <Grid container direction={"row"} wrap={"nowrap"}>
          <img className={classes.thumbnail} src={product.thumbnail} />
          <Typography align={"center"} variant={"subtitle2"}>
            {product.name}
          </Typography>
        </Grid>
        <Grid
          container
          wrap={"wrap"}
          direction={"column"}
          justify={"center"}
          alignItems="flex-end"
          className={classes.itemControl}
        >
          {product.variants.length ? (
            <Options
              product={product}
              setVariant={(variant) => setVariant(variant.variantId)}
            />
          ) : (
            <></>
          )}
          <Button
            variant={"contained"}
            color={"primary"}
            size={"small"}
            onClick={() => addItem(product.id, variant)}
          >
            Добавить
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default React.memo(ProductItem);
